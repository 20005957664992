/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import Layout from '../components/layout'

export default function NotFoundPage() {
  return (
    <Layout seo={{
      title: "Page introuvable - Swing, collectif d'audacieuses"
    }}>
      <Heading as="h1">404 Page introuvable</Heading>
    </Layout>
  )
}
